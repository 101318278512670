import { MainActivityProps } from "#lib/layout/types.js";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FileUpload,
  UploadTarget,
  CompleteUploadTarget,
} from "@arena-active/client-lib";
import { trpc } from "@arena-active/trpc-client";
import { Activity } from "@arena-active/trpc-client";
import { newUpload, UploadBackend } from "@arena-active/client-lib";
import { Button } from "@arena-active/client-lib";
import { WebPageActivity } from "./WebPageActivity";
import { DangerCircleBrokenIcon } from "#lib/Icons.js";
import {
  ToastErrorNotification,
  ToastErrorNotificationStyles,
} from "@arena-active/client-lib";
import { Toast } from "primereact/toast";
import { useAtom } from "jotai/index";
import { activeSessionStateAtom } from "../state/atoms";

type Utils = ReturnType<typeof trpc.useUtils>;
type Client = Utils["client"];

const getBackend = (
  client: Client,
  activityId: number,
  stateId: number,
): UploadBackend => ({
  getSignedUrl: async (filename) => {
    const url = await client.activity.getSignedUrl.query({
      activityId,
      stateId,
      filename,
    });
    return url;
  },
  deleteFile: async (filename) => {
    return client.activity.deleteFile.mutate({
      activityId,
      stateId,
      filename,
    });
  },
});

export interface UploadFilesActivityProps extends MainActivityProps {
  activity: Activity;
  userId: number;
}

export const UploadFilesActivity: FC<UploadFilesActivityProps> = ({
  activity,
  userId,
  onComplete,
}) => {
  const generalErrorToast = useRef<Toast>(null);
  /** The upload targets. They are either completed or active, we load the initial completed set from the api */
  const [uploads, setUploads] = useState<UploadTarget[]>([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const { isLoading, data: activityState } =
    trpc.activity.getOrCreateUserActivityState.useQuery({
      activityId: activity.id,
      userId,
    });

  const utils = trpc.useUtils();
  const { client } = utils;
  const [, refetchActiveSessionState] = useAtom(activeSessionStateAtom);

  useEffect(() => {
    refetchActiveSessionState();
  }, [activityState]);

  const backend = useMemo(() => {
    if (!activityState) {
      return {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        deleteFile: (..._args: any[]) => Promise.resolve(true),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        getSignedUrl: (..._args: any[]) => Promise.resolve(""),
      } as UploadBackend;
    }
    return getBackend(client, activity.id, activityState.id);
  }, [activity, activityState, client]);

  useEffect(() => {
    uploads.forEach((upload) => {
      upload.on("error", () => {
        generalErrorToast?.current?.show({
          sticky: true,
          content: <ToastErrorNotification />,
        });
      });
    });
  }, [uploads, generalErrorToast]);

  useEffect(() => {
    if (initialDataLoaded) {
      return;
    }
    if (!activityState) {
      return;
    }
    client.activity.getStoredFiles
      .query({
        activityId: activity.id,
        stateId: activityState.id,
      })
      .then((files) => {
        setUploads(
          files.map((f) => new CompleteUploadTarget(f.file.name, f.file.size)),
        );
        setInitialDataLoaded(true);
      });
  }, [activity, activityState, client, initialDataLoaded]);

  const onDrop = useCallback(
    (files: File[]) => {
      if (!activityState) {
        return;
      }

      // TODO(ed): What are the rules about uploading a file w/ the same name?
      // For now we just filter it out.

      const filtered = files.filter(
        (f) =>
          uploads.findIndex((u: UploadTarget) => u.filename === f.name) === -1,
      );

      const u = filtered.map((f) => newUpload(f, backend) as UploadTarget);

      setUploads((uploads) => [...uploads, ...u]);
    },
    [activityState, backend, uploads],
  );

  const onRemove = useCallback(
    (filename: string) => {
      const target = uploads.find((cu) => cu.filename === filename);

      if (!target) {
        console.error("upload target not found for", filename);
        return;
      }

      if (target.status === "success") {
        backend
          .deleteFile(filename)
          .then(() => {
            target.removeAllListeners();
            setUploads((prev) => prev.filter((u) => u.filename !== filename));
          })
          .catch((e) => {
            console.error("failed to remove", e);
          });
      } else {
        target
          .stop()
          .then(() => {
            target.removeAllListeners();
            setUploads((prev) => prev.filter((u) => u.filename !== filename));
          })
          .catch((e) => console.error("failed to remove", e));
      }
    },
    [uploads, backend],
  );

  if (isLoading) {
    return <div>No activity state yet..</div>;
  }

  return (
    <div className="flex flex-column gap-2 align-items-center">
      <Toast
        ref={generalErrorToast}
        className={ToastErrorNotificationStyles.toastContainer}
        position="bottom-right"
      />
      <div className="w-11">
        {activityState?.activity.textUrl && (
          <WebPageActivity
            frameUrl={activityState.activity.textUrl}
            enableDynamicHeight
            isComplete={true}
          />
        )}
      </div>
      <div className="w-10">
        <FileUpload
          onDrop={onDrop}
          onRemove={onRemove}
          uploads={uploads}
          maxFiles={10}
        />
      </div>
      <div className="flex m-6 w-10 align-items-center gap-5">
        <div
          className="w-2"
          style={{
            flexGrow: "1",
            backgroundColor: `var(--surface-level-4)`,
            height: "2px",
          }}
        />
        <div className="w-4 flex justify-content-center">
          <Button label="Submit" onClick={() => onComplete?.()} />
        </div>
        <div
          className="w-2"
          style={{
            flexGrow: "1",
            backgroundColor: `var(--surface-level-4)`,
            height: "2px",
          }}
        />
      </div>
      <div className="flex gap-2 w-10 align-items-center">
        <DangerCircleBrokenIcon fill="var(--text-subtitle)" />
        <p style={{ color: `var(--text-subtitle)` }}>
          Please double-check the uploaded files before continuing. Once
          submitted, you will not be able to upload them again. Thank you for
          your dilligence!
        </p>
      </div>
    </div>
  );
};
